import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// Wait for the DOM to be ready
if (typeof window !== 'undefined') {
  const container = document.getElementById('root');
  if (!container) throw new Error('Failed to find root element');
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
